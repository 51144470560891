import React, { Component } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";


export class MapContainer extends Component {

    onMapReady = (mapProps, map) => {
        const { google, places } = mapProps;
        let coords = [];
        let waypoints = [];
        places.map((place) => coords.push({ lat: place.lat, lng: place.lng }));

        //instantiate directions service and directions renderer
        const directionsService = new google.maps.DirectionsService();
        const directionsDisplay = new google.maps.DirectionsRenderer();
        //put directions renderer to render in the map
        directionsDisplay.setMap(map);
        //Getting the first coordinate in the array as the start/origin
        let start = { lat: coords[0].lat, lng: coords[0].lng };
        //Getting the last coordinate in the array as the end/destination
        let end = {
            lat: coords[coords.length - 1].lat,
            lng: coords[coords.length - 1].lng,
        };

        //putting all the coordinates between the first and last coordinate from the array as the waypoints
        for (let i = 1; i < coords.length - 1; i++) {
            waypoints.push({
                location: { lat: coords[i].lat, lng: coords[i].lng },
                stopover: true,
            });
        }

        // directions requests
        let request = {
            origin: start,
            waypoints: waypoints,
            destination: end,
            travelMode: "DRIVING",
        };
        //show results in the directionsrenderer
        directionsService.route(request, function (result, status) {
            if (status === "OK") {
                directionsDisplay.setDirections(result);
            }
        });
    };


    render() {
        return (
            <div>
                <Map
                    className="map"
                    initialCenter={this.props.center}
                    google={this.props.google}
                    onClick={this.onMapClicked}
                    onReady={this.onMapReady}
                    places={this.props.places}
                    style={{ height: "100%", position: "relative", width: "95%" }}
                />
            </div>
        );
    }
}


export default GoogleApiWrapper({
    apiKey: "AIzaSyC5J5CToErAuIQ_hO41ePkS4FoYdclaiqE"
})(MapContainer);
