import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {useParams} from "react-router-dom";
import {Col, Container, Row, Image} from "react-bootstrap";
import MapContainer from "../Map";
import Comments from "../Comments";
import {useDispatch} from "react-redux";
import {getPostAction} from "../../redux/actions/post-actions";

function Itinerary() {
    let {id} = useParams();
    const dispatch = useDispatch();
    dispatch(getPostAction('itinerary', id));

    return (
        <Container>
            <div className="my-4 mx-4">
                {getItinerary(id)}
            </div>
        </Container>
    )
}

const places = {
    1: {
        mainHeading: "4 days Trip Itinerary to Parvati Valley",
        places: "(Kasol/Rasol/Chalal/Manikaran)",
        days: [
            {
                day: 1,
                place: "Bhuntar",
                description: ['Located in the Kullu district of Himachal Pradesh, Bhuntar is a quaint little town and less touristy place. If you’re fond of architecture, there are three temples (Jagannath Temple, Basheshwar Temple, and Bijili Mahadev temple) with splendid architecture and high religious significance. You shall spot lush greenery all around the town. Surrounded by lush greenery, this small town offers a beautiful camping spot and white water Rafting. You can spend a day or an evening at Bhuntar to explore the place.', '', '<strong>Bhuntar to Kasol</strong>: Located around 30kms from Bhuntar, Kasol is a small village in the Parvati Valley and is a famous tourist place which attracts a number of visitors every year. It is surrounded by beautiful forests and is on the banks of a river. There are many good options for stay and food in the village. Spending 1]/2 days are enough to enjoy this place.'],
                images: ["/images/Itinerary/article-1/bhuntar.jpeg", "/images/Itinerary/article-1/kasol.jpg"],
            },
            {
                day: 2,
                place: "Kasol to Chalal Trek",
                description: ['With just 20 minutes of trekking through the gorgeous pine forests from Kasol, you will find yourself amidst beautiful views of Chalal. This beautiful place is less crowded than Kasol and will definitely give you some moment for yourself. Not only you will find solitude in the lap of nature but the yummy local and Israeli food of Chalal will thrill you. There are multiple cafés and eateries which serve fresh food at affordable prices.'],
                images: ["/images/Itinerary/article-1/chalal.jpg"]
            },
            {
                day: 3,
                place: "Chalal to Rasol Trek",
                description: ["Rasol is a small quaint and beautiful village located in the Parvati Valley. To reach the village, you need to hike for 3 to 4 hours from Chalal. There are limited guest houses in the village. As the hike is steep at various points, it is better to carry trekking shoes. You can spend a day in the lap of nature before heading back to Kasol."],
                images: ["/images/Itinerary/article-1/rasol.jpg"]
            },
            {
                day: 4,
                place: "Kasol to Manikaran",
                description: ["Just 5 km from Kasol, Manikaran Gurudwara is famous for its hot springs and is an important place of pilgrimage for Sikhs and Hindus. The place not only holds religious importance, but gives a magnificent view of the mighty Parvati river flowing in full flow. There is a natural warm water kund where you can take a dip and relax. Langar (boiled rice and dal cooked in the hot springs) is always on in gurudwara. There is also a Shiva temple near the gurdwara."],
                images: []
            }
        ],
        map: {
            center: { lat: 31.88514913328423, lng: 77.14329241628872 },
            places: [
                {
                    name: "Bhuntar",
                    title: "Bhuntar",
                    lat: 31.88514913328423,
                    lng: 77.14329241628872,
                    id: 1,
                },
                {
                    name: "Kasol",
                    title: "Kasol",
                    lat: 32.01068695819562,
                    lng: 77.3142403246739,
                    id: 2,
                },
                {
                    name: "Rasol",
                    title: "Rasol",
                    lat: 32.03147695904464,
                    lng: 77.2920879783781,
                    id: 3,
                },
                {
                    name: "Manikaran",
                    title: "Manikaran",
                    lat: 32.027888611010724,
                    lng: 77.34719390878284,
                    id: 4,
                },
            ]
        }
    },
    2: {
        mainHeading: "3 days Trip Itinerary to Parvati Valley",
        places: "(Tosh/Kalga/Tulga/Pulga/Kheerganga Trek)",
        days: [
            {
                day: 1,
                place: "Bhuntar to Barshaini",
                description: ["It takes around 3 hours to reach Barshaini from Bhuntar. Barshaini is a small village in Parvati Valley, with all the basic facilities. The trails to Tosh, Kheerganga Trek, and Kalga-Tulga-Pulga villages start from Barshaini.", "", '<strong>Barshaini to Tosh</strong>: With pleasant weather and breathtaking views, Tosh is a great place for a quiet vacation amidst nature. Be it views of snow-clad mountains, green valley, waterfalls, or blue skies, it has so much to offer to its visitors. Take a visit around the village and explore the views of the serene valley from different places.'],
                images: ["/images/Itinerary/article-1/bhuntar.jpeg", "/images/Itinerary/article-2/tosh.jpeg"]
            },
            {
                day: 2,
                place: "Tosh to Kalga, Tulga, and Pulga Villages",
                description: ["Far away from the hustle of city life, these three villages are very close to each other and can be reached by trekking from Barshaini. From Barshaini near the overbridge built over Parvati river, a small trek of just 3 Kms goes through dense deodar trees. This beautiful trek offers some excellent views of the Parvati valley and snow capped mountains. As very few tourists visit these villages, they are a perfect place for you to spend time with yourself."],
                images: ["/images/Itinerary/article-2/pulga2.jpg"]
            },
            {
                day: 3,
                place: "Pulga to Kheerganga Trek",
                description: ["The famous kheerganga trek is about 12 Kms and it takes around 5 - 6 hours to reach the top. The trek passes through green lush meadows present alongside the Parvati river, dense deodar trees, small waterfalls, and wooden bridges. Once you reach the top, you will have magnificent views of the whole valley. Camping at the top of the valley amidst the beautiful landscape is one of the best experiences in the mountains. Enjoy the sunset and sunrise views of the valley from your camp.", "Near the campsite, there is a natural hot water spring of the ecstatic Parvati Kund, taking a dip in the hot water will surely reduce your tiredness."],
                images: ["/images/Itinerary/article-2/kheerganga.jpg"]
            }
        ],
        map: {
            center: { lat: 31.88514913328423, lng: 77.14329241628872 },
            places: [
                {
                    name: "Bhuntar",
                    title: "Bhuntar",
                    lat: 31.88514913328423,
                    lng: 77.14329241628872,
                    id: 1,
                },
                {
                    name: "Tosh",
                    title: "Tosh",
                    lat: 32.03147695904464,
                    lng: 77.2920879783781,
                    id: 2,
                },
                {
                    name: "Pulga",
                    title: "Pulga",
                    lat: 31.995678874220115,
                    lng: 77.44149568790104,
                    id: 3,
                },
                {
                    name: "Kheerganga",
                    title: "Kheerganga",
                    lat: 31.99323506545565,
                    lng: 77.5101053953084,
                    id: 4,
                },
            ]
        }
    },
    3: {
        mainHeading: "2 days Trip Itinerary to Parvati Valley",
        places: "(Malana/Kasol)",
        days: [
            {
                day: 1,
                place: "Bhuntar to Malana",
                description: ["Nestled in the peaks of the Himalayas, Malana is a small ancient village situated on the side of Malana river. You can go by your own vehicle or bus to the base of the village and then trek uphill for around 2 Kms. Malana is famous for its oldest democracy in the world and Malana hash. The locals claim it to be one of the oldest democracies of the world, with a well-organized village council guided by their <i>devta</i> (deity) Jamlu rishi.", "This beautiful paradise is surrounded by peaks of Chanderkhani and Deo Tibba, which provide tourists with beautiful and serene views. The views of steep cliffs and snow-capped mountains will leave you in awe. Villagers here are very friendly and nice, but touching them or their belongings by an outsider is not permissible.", "You can visit Jamlu Devta Temple and shrine of Renuka Devi, though entering inside is not allowed.", "Tourists are not allowed to stay inside the village, so they can stay outside it as there are some guest houses outside the village. One day is enough to explore the village."],
                images: ["/images/Itinerary/article-3/malana.jpg"]
            },
            {
                day: 2,
                place: "Malana to Kasol",
                description: ["Kasol is pretty much the heart of Parvati Valley and very popular among travellers, especially during Summers. It is most commonly called the mini Israel of India as so many Israelis live in the region. The place offers everything - delicious food, parties, breathtaking views, trekking, and much more. Spending a day or two here is enough to explore the place and have fun."],
                images: ["/images/Itinerary/article-3/kasol.jpg"]
            }
        ],
        map: {
            center: { lat: 31.88514913328423, lng: 77.14329241628872 },
            places: [
                {
                    name: "Bhuntar",
                    title: "Bhuntar",
                    lat: 31.88514913328423,
                    lng: 77.14329241628872,
                    id: 1,
                },
                {
                    name: "Malana",
                    title: "Malana",
                    lat: 32.06206425978769,
                    lng: 77.26105751488683,
                    id: 2,
                },
                {
                    name: "Kasol",
                    title: "Kasol",
                    lat: 32.010004642540885,
                    lng: 77.31455146091729,
                    id: 3,
                },
            ]
        }
    },
    4: {
        mainHeading: '3 days Itinerary for family trip to Manali',
        days: [
            {
                day: 1,
                place: 'Explore Manali Town',
                description: ["Nestled in the magnificent Beas river valley, Manali is a popular tourist place attracting visitors all round the year. It offers breathtaking views of the snow-capped mountains and lush green valley. Even after so many tourists visiting Manali throughout the year, it has not lost even a little bit of charm.",
                'Visit Hidimba Temple, Buddhist monastery, Manu Temple, Manali wildlife sanctuary, Gauri Shankar Temple, Van vihar (public park alongside beas river). You can also go river rafting in the Beas river.',
                'In the evening, take a stroll on Mall road. There are a variety of restaurants on the mall road and you can also do some shopping here.'],
                images: ['/images/Itinerary/article-4/Day-1-1.jpeg','/images/Itinerary/article-4/Day1.jpg']
            },
            {
                day: 2,
                place: 'Manali to Kothi and Solang Valley',
                description: ['Kothi is a small village located at the foothills of Rohtang and just 13 Kms from Manali. As not many tourists visit Kothi, it is a very serene place to spend time. Solang village is just a short drive away from Kothi. To get a 360-degree view of the valley, try a ride on the ropeway. There are so many activities to do in Solang valley. You can do paragliding, quad biking, zorbing, gondola ride, and Yak riding throughout the year and skiing and snowmobiling in winters.',
                'After spending the day exploring around, if you want to enjoy the mesmerising views of the lush green valley from the window of your room, Solang and Kothi are the best places to stay. There are many hotels and restaurants available in Kothi and Solang. A few camping agencies are there if you are interested in staying in a camp near the banks of Beas river.'],
                images: ['/images/Itinerary/article-4/Day2.jpg']
            },
            {
                day: 3,
                place: 'Manali/Solang to Rohtang pass and Sissu',
                description: ['If you are fond of snow, there is no better and easily accessible place than Rohtang in the entire North India to enjoy snow activities. You will find snow here all round the year, so it is always going to be cold here. Rohtang is a high altitude pass, situated at an elevation of 13000 feet. It is situated around 50 Kms from Manali and it takes 2-3 hours to reach here from Manali. The pass is closed during winters and generally remains open from May to October.',
                'You can enjoy various adventure activities at Snow Point such as sledding, mountain biking, snow scooter, tyre drop, and skiing. Enjoy making igloos with snow.',
                'Also every vehicle coming to Rohtang pass needs a permit provided by Himachal Tourism. You can visit their center in Manali (on Mallroad) or apply online on <a href="https://rohtangpermits.nic.in/SpecialPermit/InformatoryScreen" target="_blank">rohtangpermits</a> to get the permit before your visit to Rohtang pass.',
                '',
                '<strong>Sissu</strong>: Sissu is a small beautiful village in Lahaul valley. The landscape of Lahaul valley is totally different from Manali. High altitude mountains, waterfalls, lakes, alpine meadows, fresh and cool breeze, snow clad peaks, you will find everything in this little paradise.',
                'After enjoying snow activities in Rohtang, you can head towards Sissu before moving back to Manali. Enjoy views of Sissu lake, the drive itself is very mesmerising with snow covered mountains on both sides of the road.'],
                images: ['/images/Itinerary/article-4/Day3.jpg', '/images/Itinerary/article-4/Day-3-1.jpg']
            }
        ],
        map: {
            center: { lat: 32.24165736960167, lng: 77.1892622051156 },
            places: [
                {
                    name: "Manali",
                    title: "Manali",
                    lat: 32.24165736960167,
                    lng: 77.1892622051156,
                    id: 1,
                },
                {
                    name: "Solang Valley",
                    title: "Solang Valley",
                    lat: 32.310082199259554,
                    lng: 77.17822853208887,
                    id: 2,
                },
                {
                    name: "Rohtang",
                    title: "Rohtang",
                    lat: 32.37154370108244,
                    lng: 77.2469008207148,
                    id: 3,
                },
            ]
        }
    },
    5: {
        mainHeading: '4 days Trip Itinerary to explore scenic villages around Manali',
        days: [
            {
                day: 1,
                place: 'Gulaba village',
                description: [
                    'Gulaba is a small village located around 25 Kms from Manali. It is a perfect place to find snow and get indulged in snow activities during winters. When Rohtang pass is closed due to weather conditions, Gulaba is the best alternative to tourists.',
                    'In Summer, you will find scintillating views of lush green meadows, small grasslands, snow-capped mountains, and glaciers. In Winter, you can enjoy fresh snow, skiing, snow scooter, and beautiful views of tranquil valleys covered entirely with snow. Enjoy a beautiful sunset amidst snow covered mountains. Gulaba has numerous high peaks which provide a complete view of the serene valley, you can also try paragliding to explore the full views of the valley.'
                ],
                images: ['/images/Itinerary/article-5/Day-1.jpg']
            },
            {
                day: 2,
                place: 'Jogini waterfalls, Solang, and Kothi',
                description: [
                    'Jogini waterfall is a beautiful waterfall situated near Vashisht village. It is a short and easy trek of around two hours from Vashisht temple, going through forest trails. Even though the trek is quite short, it will provide you incredible views of the mountains and valleys around, apple orchards, and water streams. At the base of the waterfall, there is a pool and Jogini mata temple. You will find a few cafes and stalls on the trek route.',
                    'Solang and Kothi villages are perfect for exploring the beauty of mountains. Spend some time near the banks of Beas river, listening to the sounds of flowing river water and awing the unparalleled views of the valley. You can also do camping near the banks of the river. There are a bunch of activities you can enjoy here - Paragliding, quad biking, zorbing, gondola ride, and Yak riding, skiing, and snowmobiling.'
                ],
                images: ['/images/Itinerary/article-5/Day-2.jpg']
            },
            {
                day: 3,
                place: 'Sethan',
                description: [
                    'Situated at high altitude, it is a perfect place to relax and lose yourself in the lap of nature. The panoramic views of Dhauladhar ranges combined with tranquility of the place make it a perfect destination for the nature lovers. Skiing and snowboarding are quite popular activities during winters, but staying in an igloo while it is snowing outside can be the best adventure and best memory of visiting Manali. You can also do trekking here as there are numerous small treks starting from Sethan. Treks such as Jobra, Pandu Ropa, and Indrasana Peak will provide you with some extra views of the mountains. It also serves as the starting point of the popular Hampta Pass trek. As Sethan is a very small village, not many options are available for staying here, but you can definitely find a few guest houses and camps.'
                ],
                images: ['/images/Itinerary/article-5/Day-3.jpg']
            },
            {
                day: 4,
                place: 'Jagatsukh village',
                description: [
                    'Located around 10 Kms from Manali, Jagatsukh is a paradise for nature lovers and photographers. The village provides picturesque views of surrounding snow-capped mountains and dense vegetation. You can visit a unique shikhara style temple dedicated to Lord Shiva and Goddess Sandhya Gayatri and a hot spring. Explore the village life in its pure natural form amidst the scenic meadows. It also serves as a base for Deo Tibba Trek.'
                ],
                images: ['/images/Itinerary/article-5/Day-4.jpg']
            }
        ],
        map: {
            center: { lat: 32.24165736960167, lng: 77.1892622051156 },
            places: [
                {
                    name: "Manali",
                    title: "Manali",
                    lat: 32.24165736960167,
                    lng: 77.1892622051156,
                    id: 1,
                },
                {
                    name: "Gulaba",
                    title: "Gulaba",
                    lat: 32.2324827657079,
                    lng: 77.19969934822781,
                    id: 2,
                },
                {
                    name: "Solang",
                    title: "Solang",
                    lat: 32.310082199259554,
                    lng: 77.17822853208887,
                    id: 3,
                },
                {
                    name: "Sethan",
                    title: "Sethan",
                    lat: 32.23593182411003,
                    lng: 77.22297009264283,
                    id: 4,
                },
                {
                    name: "Jagatsukh",
                    title: "Jagatsukh",
                    lat: 32.20151696632956,
                    lng: 77.20461843019734,
                    id: 5,
                }
            ]
        }
    },
    6: {
        mainHeading: '3 days Trip Itinerary to Dalhousie',
        places: '',
        days: [
            {
                day: 1,
                place: 'Dalhousie',
                description: [
                    {
                        text: 'Situated in Chamba district, Dalhousie is a popular hill station spread out over the five hills of Kathlog, Potreyn, Terah, Bakrota, and Bhangora. What separates it from other hill stations is its rich colonial architecture, ancient temples, and unexplored hilltops. It is a beautiful place to have a peaceful and laid back holiday. Go around visiting St. John church and St. Francis Church if you like colonial architecture. Take a stroll on mall road and do some shopping for Tibetan handicrafts and himachali traditional clothes. Sip a cup of tea while listening to the gushing sound of Subhash Bowli, a water spring with mesmerizing views of mountains in the backdrop.',
                        images: ['/images/Itinerary/article-6/Day1dalhousie.jpg']
                    },
                    '',
                    {
                        text: '<strong>Kalatop wildlife sanctuary</strong>: Just 11 Kms from Dalhousie, Kalatop Sanctuary is home to tall deodar trees, grasslands, fresh water streams, and wildlife, making it a must visit place for nature walk. A few well managed trails in the sanctuary provide trekkers a sound reason to spend more time in the sanctuary. Scintillating views of snow capped mountains from the top will make you fall in love with this place. There is a forest guest house and a few restaurants inside the sanctuary, also a small adventure park inside is ideal for kids to have some fun time.',
                        images: ['/images/Itinerary/article-6/Day1kalatopp.jpg']
                    },
                    '',
                    {
                        text: '<strong>Dainkund hill top</strong>: Drive for 30 mins from Kalatop and you will reach Dainkund hill top where you will get panoramic views of the hills and mountains around. There are a few trails as well, making it a delight for trekkers.',
                        images: []
                    },
                    '',
                    '<strong>Bakrota hill top</strong>: Bakrota is just 20 mins drive from Dalhousie. With lush green valleys and refreshing breeze, Bakrota is a heaven for nature lovers. The serene landscape and views of the entire Dhauladhar range are astonishing. When in the mountains, always plan for Sunrise and Sunset views, Bakrota is a great place to capture Sunset views amidst surrounding mountains. During winters, the whole valley gets covered in snow. Staying overnight can give you more time to spend in the lap of nature, if you want to stay away from the crowd of Dalhousie.'
                ],
                images: ['/images/Itinerary/article-6/Day1dainkund.jpg']
            },
            {
                day: 2,
                place: 'Dalhousie to Khajjiar',
                description: [
                    {
                        text: 'Khajjiar has a scenic landscape with lush green meadows, making it a picture perfect place throughout the year. In winters this whole place gets covered with snow and in Summers the whole valley blossoms with dark green colors. Surrounded by snow covered mountains and cedar forest, Khajjiar lake is definitely a treat for eyes. Horses grazing around with tall trees and mountains in the background won’t let you stop clicking photos.',
                        images: ['/images/Itinerary/article-6/Day2KhajjiarrA6.jpg','/images/Itinerary/article-6/Day2KhajjiarA6.jpg']
                    },
                    '',
                    '<strong>Khajjiar to Jot</strong>: A small hidden treasure in the Himalayas, Jot is a perfect place to spend some time with yourself.  You won’t find much to do here, but you will definitely get into love with the natural beauty of this place. With hardly any tourists, lush green meadows, sunlight falling on distant mountains, and grasslands, it is not less than a Bollywood movie scene.',
                    'Spend some time watching Sunset before heading to Chamba and stay at Chamba overnight.'
                ],
                images: ['/images/Itinerary/article-6/Day2jot.jpg']
            },
            {
                day: 3,
                place: 'Chamba',
                description: [
                    {
                        text: 'Chamba has a rich history - ancient culture, museums, temples. Bhuri Singh museum has a collection of antique pieces of royal jewellery, art, musical instruments, scripts, and stones. Visit Akhand Chandi Palace to know the Mughal lifestyle and British architecture. Visit Rang mahal to shop local handmade goods.',
                        images: ['/images/Itinerary/article-6/Day3rangmahal.jpg']
                    },
                    'There are some more temples to visit in Chamba - Chamunda devi temple, Hariraya Temple, Sui Mata Temple, and Lakshmi Narayana Temple. Champavati Temple was built in shikhara style.',
                    '',
                    '<strong>Chamba to Chamera lake</strong>: Nestled between the mountains, Chamera lake is a delight for photo enthusiasts. Its turquoise colored water amidst surrounding mountains makes it a perfect picnic spot. Enjoy water sports - kayaking, boating, sailing, canoeing, and fishing - while adoring the beauty of the surrounding mountains.'
                ],
                images: ['/images/Itinerary/article-6/Day3ChameralakeA6.jpg', '/images/Itinerary/article-6/Day3ChameralakeA6-2.jpg']
            }
        ],
        howToReach: {
            description: [
                '<h4>How to reach Dalhousie from Delhi</h4> From Delhi, Bus takes around 10 hours to reach Dalhousie. It is best to take an overnight bus from Delhi so that you save one day of your trip. Nearest railway station and airport to Dalhousie are in Pathankot which is around 80 Kms from Dalhousie. If you travel by train/air, you will have to take a taxi from Pathankot.'
            ]
        },
        bestTimeToVisit: [
            'March to June in Summers',
            'October to February in Winters',
            'December and January for Snowfall'
        ],
        map: {
            center: { lat: 32.536310695866966, lng: 75.97943336996568 },
            places: [
                {
                    name: "Dalhousie",
                    title: "Dalhousie",
                    lat: 32.536310695866966,
                    lng: 75.97943336996568,
                    id: 1,
                },
                {
                    name: "Kalatop",
                    title: "Kalatop",
                    lat: 32.55157471831461,
                    lng: 76.01798769402484,
                    id: 2,
                },
                {
                    name: "Dainkund",
                    title: "Dainkund",
                    lat: 32.5251911848118,
                    lng: 76.02386027680141,
                    id: 3,
                },
                {
                    name: "Bakrota",
                    title: "Bakrota",
                    lat: 32.53035575558483,
                    lng: 76.00500582926234,
                    id: 4,
                },
                {
                    name: "Khajjiar",
                    title: "Khajjiar",
                    lat: 32.55065013948863,
                    lng: 76.061379759799,
                    id: 5,
                },
                {
                    name: "Chamba",
                    title: "Chamba",
                    lat: 32.556462716498835,
                    lng: 76.12195261967723,
                    id: 6,
                },
                {
                    name: "Chamera Lake",
                    title: "Chamera Lake",
                    lat: 32.62014557469913,
                    lng: 76.00842664275832,
                    id: 7,
                }
            ]
        }
    },
    7: {
        mainHeading: '4 days Trek Itinerary from Dalhousie',
        places: '',
        days: [
            {
                day: 1,
                place: 'Dalhousie to Dainkund',
                description: [
                    {
                        text: 'Drive from Dalhousie to Dainkund base which is around 12 Kms from Dalhousie and trek to Dainkund hill top from there. The mesmerizing views of snow covered mountains will give you enough reasons to go for the trek ahead.',
                        images: ['/images/Itinerary/article-7/Day1dainkundinA6A7.jpeg']
                    },
                    {
                        text: `<strong>Dainkund to Jot Trek</strong>: The trek starts from Dainkund hilltop, the highest peak of Dalhousie, and goes through wide open valleys surrounded by Pir Panjal mountains Range. The landscape of the trek with views of sunlight falling on mountains and Rivers flowing amidst mountains is icing on the cake. Floating clouds around the high altitude mountains will make you feel like you are walking on the clouds. You will definitely find herds of sheeps grazing around. This is quite an easy trek and takes around 4 hours to complete. You can stay overnight in a camp or a guest house.`,
                        images: ['/images/Itinerary/article-7/Day2jotA6Day1A7.jpeg']
                    }
                ],
                images: []
            },
            {
                day: 2,
                place: 'Jot to Khajjiar Trek',
                description: [
                    'The landscape today will be little different from the one you must have experienced yesterday. Wide open lush green meadows cradled by dense deodar trees and with some beautiful cottages around will make you feel like staying here forever. At the end of the trek, views of Khajjiar lake with horses grazing in the distance are worth the effort. You can stay overnight in a camp or a guest house.'
                ],
                images: ['/images/Itinerary/article-7/Day2khajjiarA7.jpeg']
            },
            {
                day: 3,
                place: 'Khajjiar to Kalatop',
                description: [
                    'Kalatop wildlife sanctuary is a preserved forest area with deodar, rhododendron, and oak trees. With a variety of wildlife and flora, it is a paradise for bird watchers. It is the best deep jungle walk to experience the wildlife and nature in its most untouchable form and takes around 6 hours to complete. Enjoy astonishing views of sunset from your campsite and capture the milky way as the sky will be clear most of the time at night.'
                ],
                images: ['/images/Itinerary/article-7/Day1kalatopA6Day3A7.jpeg']
            },
            {
                day: 4,
                place: 'Kalatop to Dalhousie',
                description: [
                    'Trek to Dalhousie from Kalatop takes around 6 hours and goes through Panchpula waterfall. You can enjoy a small picnic near the waterfall while eating maggi before heading towards Dalhousie. Also the waterfall may be in full flow if you are visiting in monsoon season, so you can climb up to the top of the waterfall to get a closer view.'                ],
                images: ['/images/Itinerary/article-7/Day4PanchpulaA7.jpg']
            }
        ],
        howToReach: {
            description: [
                '<h4>How to reach Dalhousie from Delhi</h4> From Delhi, Bus takes around 10 hours to reach Dalhousie. It is best to take an overnight bus from Delhi so that you save one day of your trip. Nearest railway station and airport to Dalhousie are in Pathankot which is around 80 Kms from Dalhousie. If you travel by train/air, you will have to take a taxi from Pathankot.'
            ]
        },
        bestTimeToVisit: [
            'March to June in Summers',
            'October to February in Winters',
            'December and January for Snowfall'
        ],
        map: {
            center: { lat: 32.536310695866966, lng: 75.97943336996568 },
            places: [
                {
                    name: "Dalhousie",
                    title: "Dalhousie",
                    lat: 32.536310695866966,
                    lng: 75.97943336996568,
                    id: 1,
                },
                {
                    name: "Kalatop",
                    title: "Kalatop",
                    lat: 32.55157471831461,
                    lng: 76.01798769402484,
                    id: 2,
                },
                {
                    name: "Dainkund",
                    title: "Dainkund",
                    lat: 32.5251911848118,
                    lng: 76.02386027680141,
                    id: 3,
                },
                {
                    name: "Bakrota",
                    title: "Bakrota",
                    lat: 32.53035575558483,
                    lng: 76.00500582926234,
                    id: 4,
                },
                {
                    name: "Khajjiar",
                    title: "Khajjiar",
                    lat: 32.55065013948863,
                    lng: 76.061379759799,
                    id: 5,
                },
                {
                    name: "Chamba",
                    title: "Chamba",
                    lat: 32.556462716498835,
                    lng: 76.12195261967723,
                    id: 6,
                },
                {
                    name: "Chamera Lake",
                    title: "Chamera Lake",
                    lat: 32.62014557469913,
                    lng: 76.00842664275832,
                    id: 7,
                }
            ]
        }
    }
};

function getDescription(desc) {
    if (typeof desc === 'string') {
        return (<>{ReactHtmlParser(desc)} <br /></>)
    } else if (typeof desc === 'object') {
        return (
            <>
                {ReactHtmlParser(desc.text)} <br />
                {desc.images.map((image, index) => <Image key={index} src={image} alt="" className="align-middle my-3 itinerary-image" rounded fluid/>)}
            </>
        )
    } else {
        return '';
    }
}


function getItinerary(id) {
    const itinerary = places[id];

    return (
        <>
            <Row>
                <Col>
                    <h3 className="d-inline-block" style={{color: '#333c7c', fontWeight: 300}}>{itinerary.mainHeading}</h3>{itinerary.places}
                    <hr/>
                    {itinerary.days.map(day => (
                        <div key={day.day}>
                            <h5 className="text-center">Day {day.day}</h5>
                            <p>
                                <strong>{day.place}</strong>: {day.description.map(desc => getDescription(desc))}
                            </p>
                            {day.images.map((image, index) => <Image key={index} src={image} alt="" className="align-middle mb-3 itinerary-image" rounded fluid/>)}
                        </div>
                    ))}
                    <hr/>
                    {
                        itinerary.howToReach && itinerary.howToReach.description.map(desc => (
                            <p>{ReactHtmlParser(desc)}</p>
                        ))
                    }
                    {
                        itinerary.bestTimeToVisit && itinerary.bestTimeToVisit.length > 0 && (
                            <>
                                <hr/>
                                <h4>Best Time to Visit</h4>
                            </>
                        )
                    }
                    {
                        itinerary.bestTimeToVisit && itinerary.bestTimeToVisit.map(desc => (
                            <li>{ReactHtmlParser(desc)}</li>
                        ))
                    }
                    {
                        itinerary.bestTimeToVisit && itinerary.bestTimeToVisit.length > 0 && (<br/>)
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{height: '50vh', width: '100%'}}>
                        <MapContainer center={itinerary.map.center} places={itinerary.map.places}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Comments postType="itinerary" postId={id}/>
                </Col>
            </Row>
        </>
    );

}


export default Itinerary;