import { combineReducers } from 'redux'
import postsReducer from "./posts-reducer";
import userProfileReducer from "./user-profile-reducer";
import itineraryPlannerReducer from "./itinerary-planner-reducer";

const rootReducer = combineReducers({
    post: postsReducer,
    userProfile: userProfileReducer,
    itineraryPlanner: itineraryPlannerReducer
})

export default rootReducer;