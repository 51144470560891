export const GET_COMMENTS = 'GET_COMMENTS';
export const SET_POST = 'SET_POST';
export const ADD_COMMENTS = 'ADD_COMMENTS';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';


// Itinerary Planner
export const SET_AVAILABLE_COUNTRIES = 'SET_AVAILABLE_COUNTRIES';
export const SET_AVAILABLE_STATES = 'SET_AVAILABLE_STATES';
export const SET_AVAILABLE_SOURCES = 'SET_AVAILABLE_SOURCES';
export const SET_AVAILABLE_DESTINATIONS = 'SET_AVAILABLE_DESTINATIONS';
export const SET_AVAILABLE_TRAVEL_DURATION = 'SET_AVAILABLE_TRAVEL_DURATION';
export const SET_AVAILABLE_TRAVEL_TYPE = 'SET_AVAILABLE_TRAVEL_TYPE';