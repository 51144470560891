export const getEnvData = (env = process.env.NODE_ENV) => ({
    development: {
        api: {
            url: 'http://localhost:3001'
        }
    },
    production: {
        api: {
            url: 'https://api.tripfia.com'
        }
    }
}[env]);
