import React from "react";
import {Badge, Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

function PlacesToVisit() {
    return (
        <div className="PlacesToVisit">
            <Container className="articles-container">
                <Row className="mt-2">
                    <Col sm={12} md={4} className="mb-2">
                        <Card className="shadow my-sm-4">
                            <Card.Img variant="top" src={`/images/best-places/sept/card-image.jpg`} className="img-fluid"/>
                            <Card.Body>
                                <Card.Title>5 Best places to visit in India in September <Badge variant="success">New</Badge></Card.Title>
                                <Button variant="primary"><Link to={`/places/1`} style={{ color: 'inherit', textDecoration: 'none'}}>View Details</Link></Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PlacesToVisit