import {Badge, Button, Card} from "react-bootstrap";
import {Link} from "react-router-dom";

const Article = (props) => {
    return (
            <Card className="shadow my-sm-4">
                <Card.Img variant="top" src={`/images/Itinerary/article-${props.articleId}/card-image.jpg`} className="img-fluid"/>
                <Card.Body>
                    <Card.Title>{props.heading} {props.showNewBadge ? <Badge variant="success">New</Badge> : ''}</Card.Title>
                    <Card.Text>
                        <strong>{props.subHeading}</strong>
                    </Card.Text>
                    <Button variant={`primary ${props.bottomBorder ? 'btn-card-bottom' : ''}`}><Link to={`/itinerary/${props.articleId}`} style={{ color: 'inherit', textDecoration: 'none'}}>View Itinerary</Link></Button>
                </Card.Body>
            </Card>
    )
};

export default Article;