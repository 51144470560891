import {
    SET_AVAILABLE_COUNTRIES,
    SET_AVAILABLE_DESTINATIONS,
    SET_AVAILABLE_SOURCES,
    SET_AVAILABLE_STATES,
    SET_AVAILABLE_TRAVEL_DURATION,
    SET_AVAILABLE_TRAVEL_TYPE
} from "../actions/action-constants";

export default function itineraryPlannerReducer(state = {
    selectedValues: {
        countries: [],
        states: [],
        sources: [],
        destinations: [],
        travelDuration: [],
        travelType: [],
    },
    availableValues: {
        countries: [],
        states: [],
        sources: [],
        destinations: [],
        travelDuration: [],
        travelType: [],
    }
}, action) {
    switch (action.type) {
        case SET_AVAILABLE_COUNTRIES:
            return {
                ...state,
                availableValues: {
                    ...state.availableValues,
                    countries: action.data,
                }
            };
    case SET_AVAILABLE_STATES:
            return {
                ...state,
                availableValues: {
                    ...state.availableValues,
                    states: action.data,
                }
            }
    case SET_AVAILABLE_SOURCES:
        return {
            ...state,
            availableValues: {
                ...state.availableValues,
                sources: action.data,
            }
        }
    case SET_AVAILABLE_DESTINATIONS:
        return {
            ...state,
            availableValues: {
                ...state.availableValues,
                destinations: action.data,
            }
        }
    case SET_AVAILABLE_TRAVEL_DURATION:
        return {
            ...state,
            availableValues: {
                ...state.availableValues,
                travelDuration: action.data,
            }
        }
    case SET_AVAILABLE_TRAVEL_TYPE:
        return {
            ...state,
            availableValues: {
                ...state.availableValues,
                travelType: action.data,
            }
        }
    default:
        return state;
    }
}