import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import './App.css';
import Home from './components/Home'
import PlacesToVisit from './components/PlacesToVisit'
import Places from './components/Places'
import ItineraryHome from './components/ItineraryHome'
import Itinerary from './components/Itinerary'
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
        <Router>
            <Header/>
            <div className="pb-5">
                <Switch>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route exact path="/itinerary">
                        <ItineraryHome/>
                    </Route>
                    <Route exact path="/best-places">
                        <PlacesToVisit/>
                    </Route>
                    <Route exact path="/itinerary/:id">
                        <Itinerary/>
                    </Route>
                    <Route exact path="/places/:id">
                        <Places/>
                    </Route>
                </Switch>
            </div>
            <Footer/>
        </Router>
    </div>
  );
}

export default App;
