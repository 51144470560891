import React from "react";
import {Col, Container, Row} from "react-bootstrap";

import Article from "../Article";

function ItineraryHome() {
    return (
        <div className="Home">
            <Container className="articles-container">
                <Row style={{justifyContent: 'center'}} className="mt-2">
                    <Col sm={12} md={4} className="mb-2">
                        <Article heading="4 days Trek Itinerary from Dalhousie" subHeading="" articleId="7" showNewBadge={true}/>
                    </Col>
                    <Col sm={12} md={4} className="mb-2">
                        <Article heading="3 days Trip Itinerary to Dalhousie" subHeading="" articleId="6"/>
                    </Col>
                    <Col sm={12} md={4} className="mb-2">
                        <Article heading="Kasol To Manikaran" subHeading="4 days Trip Itinerary to Parvati Valley (Kasol/Rasol/Chalal/Manikaran)" articleId="1"/>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={12} md={4} className="mb-2">
                        <Article heading="Tosh To Kheerganga" subHeading="3 days Trip Itinerary to Parvati Valley (Kasol/Rasol/Chalal/Manikaran)" articleId="2"/>
                    </Col>
                    <Col sm={12} md={4} className="mb-2">
                        <Article heading="Malana & kasol" subHeading="2 days Trip Itinerary to Parvati Valley (Malana, Kasol)" articleId="3"/>
                    </Col>
                    <Col sm={12} md={4} className="mb-2">
                        <Article heading="Manali" subHeading="3 days Itinerary for family trip to Manali" articleId="4"/>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={12} md={4} className="mb-2">
                        <Article heading="Manali" subHeading="4 days Trip Itinerary to explore scenic villages around Manali" articleId="5"/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ItineraryHome;
