import React, {useState} from "react";
import {Container, Dropdown, Nav, Navbar, Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faInstagram} from "@fortawesome/free-brands-svg-icons";
import { useLocation, Link } from "react-router-dom";
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import {useDispatch} from "react-redux";
import {setUserProfile} from "../../redux/actions/user-profile-action";

const Header = props => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [loginData, setLoginData] = useState(
        localStorage.getItem('tripfiaLoginData')
            ? JSON.parse(localStorage.getItem('tripfiaLoginData')): null
    );
    if (localStorage.getItem('tripfiaLoginData')) {
        dispatch(setUserProfile(JSON.parse(localStorage.getItem('tripfiaLoginData'))));
    }
    const handleLoginSuccess = (response) => {
        setLoginData(response);
        dispatch(setUserProfile(response));
        localStorage.setItem('tripfiaLoginData', JSON.stringify(response));
    }
    const handleLoginFailure = (response) => {
        console.log(response);
    }

    const handleLogoutSuccess = () => {
        setLoginData(null);
        dispatch(setUserProfile(null));
        localStorage.removeItem('tripfiaLoginData');
        console.log("Logout successful");
    }
    const handleLogoutFailure = () => {
        console.log("Logout failed");
    }

    return (
        <Navbar bg="light" className="py-0" expand="md">
            <svg width="0" height="0">
                <radialGradient id="rg" r="150%" cx="30%" cy="107%">
                    <stop stop-color="#fdf497" offset="0" />
                    <stop stop-color="#fdf497" offset="0.05" />
                    <stop stop-color="#fd5949" offset="0.45" />
                    <stop stop-color="#d6249f" offset="0.6" />
                    <stop stop-color="#285AEB" offset="0.9" />
                </radialGradient>
            </svg>
            <Container>
                <Navbar.Brand as={Link} to="/" rel="noreferrer" style={{ color: '#333c7c' }}><img src="/logo-tripfia.png" alt="" width="60"  className="d-inline-block align-middle"/>TripFia</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav activeKey={location.pathname} className="me-auto">
                        <Nav.Link as={Link} to="/" eventKey="/">Home</Nav.Link>
                        <Nav.Link as={Link} to="/itinerary" eventKey="/itinerary">Itinerary</Nav.Link>
                        <Nav.Link as={Link} to="/best-places" eventKey="/best-places">Best Places</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <a className="me-2" href="https://www.instagram.com/tripfia" target={"_blank"} style={{ color: 'inherit'}} rel={"noreferrer"}>
                    <FontAwesomeIcon icon={faInstagram} size="2x"/>
                </a>
                <a className="me-2" href="https://www.facebook.com/tripfia" target={"_blank"} style={{ color: 'inherit'}} rel={"noreferrer"}>
                    <FontAwesomeIcon icon={faFacebookSquare} size="2x"/>
                </a>
                {
                    !loginData && <GoogleLogin
                        className="shadow btn-google-sign-in"
                        clientId="1074173636376-c2kk46ir5mk9cginhh0m3l72hdlnhaqs.apps.googleusercontent.com"
                        buttonText="Sign In"
                        onSuccess={handleLoginSuccess}
                        onFailure={handleLoginFailure}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                    />
                }
                {
                    loginData && (
                        <Dropdown className="user-loggedin-dropdown">
                            <Dropdown.Toggle id="dropdown-basic">
                                <span>
                                    <Image roundedCircle src={loginData.profileObj.imageUrl} className="me-1" style={{width: '25px'}}/>
                                    Hi <span className="user-profile-description">{loginData.profileObj.givenName}</span>
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="user-profile-description-dropdown p-0">
                                <Dropdown.Item className="p-0"><GoogleLogout
                                    clientId="1074173636376-c2kk46ir5mk9cginhh0m3l72hdlnhaqs.apps.googleusercontent.com"
                                    buttonText="Logout"
                                    onLogoutSuccess={handleLogoutSuccess}
                                    onFailure={handleLogoutFailure}
                                >
                                </GoogleLogout></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                }
            </Container>
        </Navbar>
    )
};

export default Header;
