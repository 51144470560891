import {
    addComments,
    getPost,
    postUnLike,
    updatePostLike,
    updatePostCommentLike,
    postCommentUnLike,
    postCommentDelete,
    postCommentEdit
} from "../../api/posts";
import {SET_POST} from "./action-constants";

export const getPostAction = (postType, postId) => {
    return async (dispatch) => {
        try  {
            const post = await getPost(postType, postId);
            dispatch({
                type: SET_POST,
                data: post
            });
        } catch (error) {
            console.log('getPostAction: cannot fetch post')
        }
    }
}

export const updatePostLikeAction = (postType, postId, userId) => {
    return async (dispatch) => {
        try  {
            const post = await updatePostLike(postType, postId, userId);
            dispatch({
                type: SET_POST,
                data: post
            });
        } catch (error) {
            console.log('updatePostLikeAction: cannot update post like')
        }
    }
}

export const postUnLikeAction = (postType, postId, userId) => {
    return async (dispatch) => {
        try  {
            const post = await postUnLike(postType, postId, userId);
            dispatch({
                type: SET_POST,
                data: post
            });
        } catch (error) {
            console.log('postUnLikeAction: cannot update post like')
        }
    }
}

export const addCommentToPost = (postType, postId, userName, profileImage, userId, text) => {
    return async (dispatch) => {
        try  {
            const post = await addComments(postType, postId, userName, profileImage, userId, text);
            dispatch({
                type: SET_POST,
                data: post
            });
        } catch (error) {
            console.log('addCommentToPost: cannot add comment to post')
        }
    }
}

export const postCommentLikeAction = (commentId, postType, postId, userId) => {
    return async (dispatch) => {
        try  {
            const post = await updatePostCommentLike(commentId, postType, postId, userId);
            dispatch({
                type: SET_POST,
                data: post
            });
        } catch (error) {
            console.log('updatePostLikeAction: cannot update post like')
        }
    }
}

export const postCommentUnLikeAction = (commentId, postType, postId, userId) => {
    return async (dispatch) => {
        try  {
            const post = await postCommentUnLike(commentId, postType, postId, userId);
            dispatch({
                type: SET_POST,
                data: post
            });
        } catch (error) {
            console.log('postUnLikeAction: cannot update post like')
        }
    }
}

export const postCommentDeleteAction = (commentId, postType, postId) => {
    return async (dispatch) => {
        try  {
            const post = await postCommentDelete(commentId, postType, postId);
            dispatch({
                type: SET_POST,
                data: post
            });
        } catch (error) {
            console.log('postCommentDeleteAction: cannot delete comment')
        }
    }
}

export const updateCommentAction = (commentId, postType, postId, commentText) => {
    return async (dispatch) => {
        try  {
            const post = await postCommentEdit(commentId, postType, postId, commentText);
            dispatch({
                type: SET_POST,
                data: post
            });
        } catch (error) {
            console.log('updateCommentAction: cannot update post like')
        }
    }
}

