import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {useParams} from "react-router-dom";
import {Col, Container, Row, Image} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {getPostAction} from "../../redux/actions/post-actions";
import Comments from "../Comments";

function Places() {
    let {id} = useParams();
    const dispatch = useDispatch();
    dispatch(getPostAction('places', id));

    return (
        <Container>
            <div className="my-4 mx-4">
                {getPlaces(id)}
            </div>
        </Container>
    )
}

const places = {
    1: {
        mainHeading: "5 Best places to visit in India in September",
        places: "(Valley of flowers/Kashmir/Spiti valley/Chopta and Deoriatal/Tawang)",
        sections: [
            {
                id: 1,
                place: "Valley of flowers and Hemkund Sahib, Uttarakhand",
                description: ["Valley of flower valley has been declared a Unesco World Heritage Site and is rich in varied flora and fauna. If you appreciate nature even a little bit, you will definitely fall in love with the landscape of this valley. And after doing this trek, there are high chances that you will fall in love with trekking too. Valley of flowers is one of the best treks in India. Beautiful landscape, colorful flowers, lush green valley, views of glaciers, freshwater streams accompanying you throughout the trek, sounds of flowing water, clear skies, fresh breeze, and village life of Uttarakhand make it the perfect place to awe the beauty of himalayas. You will come across beautiful flowers in different shapes, sizes, and textures. The trek to the valley of flowers is quite an easy trek. But trekking to Hemkund Sahib is quite challenging, you will trek from 9600 ft to 14000 ft and come back within a day, so it is very important to be mentally prepared for the trek."],
                images: ['/images/best-places/sept/vof.JPG'],
                itinerary: [
                    {
                        day: 1,
                        description: ['Rishikesh to Govindghat drive for around 11 hours']
                    },
                    {
                        day: 2,
                        description: ['Govindghat to Poolna drive for 1 hour and Poolna to Ghangaria trek for around 6 hours']
                    },
                    {
                        day: 3,
                        description: ['Gangaria to valley of flowers trek and back for around 8 hours'],
                    },
                    {
                        day: 4,
                        description: ['Ghangaria to Hemkund Sahib trek and back for around 10 hours']
                    },
                    {
                        day: 5,
                        description: ['Ghangaria to Govindghat trek for around 5 hours']
                    },
                    {
                        day: 6,
                        description: ['Govindghat to Rishikesh for around 11 hours']
                    }
                ]
            },
            {
                id: 2,
                place: 'Kashmir',
                description: ['Kashmir is a land of gardens, lakes, valleys, orchards, and grasslands. It has a vast landscape which attracts all kinds of visitors. If you like snow and snow activities, Gulmarg is your go to place. If you like greenery and want to spend some time sitting along the side of the river, pahalgam and sonmarg will provide you with amazing views. For people liking grasslands and lakes, try trekking in offbeat locations. And if you want to spend your vacation exploring gardens, eating in finest restaurants, and staying in a houseboat, Srinagar will definitely appeal to you.'],
                images: ['/images/best-places/sept/Kashmir.jpeg'],
                itinerary: []
            },
            {
                id: 3,
                place: 'Spiti valley, Himachal Pradesh',
                description: ['Charm of Spiti valley is beyond imaginations. If you are an adventure freak, you will love the challenging landscape and rough terrains of this pristine valley. This place has so much to offer to the tourists - trekking, river rafting, camping, monasteries, lakes, star gazing, mountains, nature walks, national park, and the list goes on. Enjoy the nature walk in Pin valley national park. Do camping and stargazing on the banks of Chandratal and Dhankar lakes. Visit Buddhist monasteries to admire the architecture and spend time with yourself.  September is a good time to visit the valley before routes to this beautiful valley close due to snowfall. Spiti can be accessed from Manali as well as Shimla, both the routes provide unique and challenging experience, but Shimla route takes more time than the one from Manali.',
                    '',
                    '<strong>Quick Itinerary to Spiti valley from Manali</strong>: Manali - Losar - Kibber - Hikkim - Komik - Kaza - Chandratal - Pin valley national park - Dhankar lake - Tabo',
                    '',
                    '<strong>Quick Itinerary to Spiti valley from Shimla</strong>: Shimla - Narkanda - Reckong Peo - Nako - Tabo - Dhankar Lake - Pin valley national park - Kaza - Chandratal - Hikkim - Komik - Kibber'
                ],
                images: ['/images/best-places/sept/Spiti.jpeg'],
                itinerary: []
            },
            {
                id: 4,
                place: 'Chopta and Deoriatal, Uttarakhand',
                description: ['For an explicit trekking experience, Chopta is the best place to visit. The trek route goes through dense alpine forests and a variety of flora in the region. When you reach Chandrashila peak (the highest point of the trek), you will feel that you are at the top of everything - clouds, hills, and civilization. Autumn is a perfect time to get stunning views of Garhwal himalayas. In the morning, sunlight falling on these snowy mountains will give the illusion of glittering gold. And if you are a birder, you will soon fall in love with this trek as you will see the varieties of birds on the route, especially Monal, state bird of Uttarakhand.'],
                images: ['/images/best-places/sept/chopta.jpeg'],
                itinerary: [
                    {
                        day: 1,
                        description: ['Rishikesh to Sari village drive for around 7 hours and Sari to Deoriatal trek for around 1.5 hours']
                    },
                    {
                        day: 2,
                        description: ['Deoriatal to Chopta trek for around 10 hours']
                    },
                    {
                        day: 3,
                        description: ['Trek from Chopta to Tungnath and Chandrashila peak and back for around 5 hours. You can drive back from Chopta to Rishikesh the same day if you start trekking to Tungnath early in the morning.']
                    }
                ]
            },
            {
                id: 5,
                place: 'Tawang, Arunachal Pradesh',
                description: ['Tawang is a perfect place to visit for Historical as well as nature purposes. Tawang offers spectacular views of mountains, lakes, monasteries, rivers, stupas, and waterfalls. Situated atop a hill, Tawang monastery is the second biggest and oldest monastery in Asia. Visit Nuranag waterfall for tranquility and magnificent views of nature. Admire the beauty of blue colored, crystal clear water of Sela lake. Tawang also offers numerous trekking routes to adventure seekers to get panoramic views of Tibetan villages and to capture beauty of wildlife.'],
                images: ['/images/best-places/sept/tawang.jpg'],
                itinerary: []
            }
        ]
    },
};

function getPlaces(id) {
    const place = places[id];

    return (
        <>
            <Row>
                <Col>
                    <h3 className="d-inline-block" style={{color: '#333c7c', fontWeight: 300}}>{place.mainHeading}</h3>{place.places}
                    <hr/>
                    {place.sections.map(section => (
                        <div key={section.id}>
                            <h4 className="text-center">{section.place}</h4>
                            <p>
                                {section.description.map(desc => (<>{ReactHtmlParser(desc)} <br /></>))}
                            </p>
                            {section.itinerary.length > 0 && <div><strong>Quick Itinerary</strong></div>}
                            {section.itinerary.length > 0 && section.itinerary.map(itinerary => (
                                <div>
                                    <strong>Day {itinerary.day}</strong> {itinerary.description.map(desc => (<>{ReactHtmlParser(desc)} <br /></>))}
                                </div>
                            ))}
                            {section.itinerary.length > 0 && <br/>}
                            {section.images.map((image, index) => <Image key={index} src={image} alt="" className="align-middle mb-3 itinerary-image" rounded fluid/>)}
                        </div>
                    ))}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Comments postType="places" postId={id}/>
                </Col>
            </Row>
        </>
    );

}


export default Places;