import React, {useState} from "react";
import {Col, Image, Row, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentAlt, faThumbsUp, faTrashAlt, faEdit} from "@fortawesome/free-regular-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {
    addCommentToPost,
    postCommentLikeAction,
    postCommentUnLikeAction,
    postUnLikeAction,
    updatePostLikeAction,
    postCommentDeleteAction,
    updateCommentAction
} from "../../redux/actions/post-actions";

const Comments = props => {
    const dispatch = useDispatch();
    const [isCommentSectionVisible, setIsCommentSectionVisible] = useState(false)
    const [commentText, setCommentText] = useState('')
    const postDetails = useSelector(state => state.post.postDetails);
    const { likes, comments } = postDetails;
    const userDetails = useSelector(state => state.userProfile.userDetails);
    const isAlreadyLiked = () => {
        return postDetails?.likes.some(like => like.userId === userDetails?.profileObj?.googleId);
    }

    return (
        <div className="section-comments mt-3">
            <Row>
                <Col>
                    <span
                        className="comment-like-count"
                    >
                        <FontAwesomeIcon icon={faThumbsUp} size="sm"/> {likes ? likes.length: 0}
                    </span>
                </Col>
                <Col>
                    <p
                        className="text-muted text-end pe-1 comment-like-count"
                        onClick={() => setIsCommentSectionVisible(!isCommentSectionVisible)}
                    >
                        {comments.length} {comments?.length === 1 ? 'comment' : 'comments'}
                    </p>
                </Col>
            </Row>
            <hr className="mb-2 mt-1"/>
            <Row className="text-center">
                <Col
                    className={`${!userDetails ? 'cursor-ban': ''} comment-like-icon-section ${isAlreadyLiked() ? 'like-already-likes': ''}`}
                    title={!userDetails ? 'Please login to like': '' }
                    onClick={() => {
                        if(userDetails) {
                            if(isAlreadyLiked()) {
                                dispatch(postUnLikeAction(props.postType, props.postId, userDetails.profileObj.googleId))
                            } else {
                                dispatch(updatePostLikeAction(props.postType, props.postId, userDetails.profileObj.googleId))
                            }
                        }
                    }}>
                    <span><FontAwesomeIcon icon={faThumbsUp} size="lg"/> Like</span>
                </Col>
                <Col
                    className={`comment-like-icon-section`}
                    title={!userDetails ? 'Please login to comment': '' }
                    onClick={() => setIsCommentSectionVisible(!isCommentSectionVisible)}
                >
                    <span><FontAwesomeIcon icon={faCommentAlt} size="lg"/> Comment</span>
                </Col>
            </Row>
            <hr className="mt-2"/>
            {
                isCommentSectionVisible &&
                <input
                    type="text"
                    placeholder="Write a comment..."
                    className={`w-100 comment-input mb-2 ${!userDetails ? 'cursor-ban': ''}`}
                    disabled={!userDetails}
                    title={!userDetails ? 'Please login to comment': '' }
                    value={commentText}
                    onChange={event => { setCommentText(event.target.value); }}
                    onKeyPress={(event) => {
                        if(event.key === "Enter") {
                            dispatch(addCommentToPost(
                                props.postType,
                                props.postId,
                                `${userDetails.profileObj.givenName} ${userDetails.profileObj.familyName}`,
                                userDetails.profileObj.imageUrl,
                                userDetails?.profileObj?.googleId,
                                commentText))
                            setCommentText('')
                        }
                    }}
                />
            }
            {
                isCommentSectionVisible && comments.map((comment, i) => <Comment
                    key={i}
                    comment={comment}
                    postType={props.postType}
                    postId={props.postId}
                />)
            }
        </div>
    )
}

const Comment = props => {
    const [isEditMode, setEditMode] = useState(false);
    const [editCommentText, setEditCommentText] = useState(props.comment.text);
    const userDetails = useSelector(state => state.userProfile.userDetails);
    const dispatch = useDispatch();
    const isCommentAlreadyLiked = () => {
        return props.comment?.likes.some(like => like.userId === userDetails?.profileObj?.googleId);
    }
    const canDeleteComment = () => {
        return props.comment?.userId === userDetails?.profileObj?.googleId;
    }
    return (
        <Row className="my-2">
            <Col style={{ flexGrow: 0 }}>
                <Image src={props.comment.profileImage} roundedCircle style={{width: '30px'}}/>
            </Col>
            {
                isEditMode ? (
                    <Col className="user-comment-edit-text-col px-0">
                        <input
                            autoFocus
                            type="text"
                            value={editCommentText}
                            onChange={event => { setEditCommentText(event.target.value); }}
                            className={`w-75 comment-input-sm`}
                        />
                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="mx-2"
                            onClick={() => {
                                setEditMode(false)
                                setEditCommentText(props.comment.text)
                            }}
                        >Cancel</Button>
                        <Button
                            variant="outline-success"
                            size="sm"
                            onClick={() => {
                                dispatch(updateCommentAction(props.comment._id, props.postType, props.postId, editCommentText))
                                setEditMode(false)
                                setEditCommentText(editCommentText)
                            }}
                        >Update</Button>
                    </Col>
                ) : (
                    <Col className="user-comment-text-col ps-0">
                        <p className="py-2 px-3 position-relative d-inline-block">
                            <strong>{props.comment.userName}</strong>
                            <br />{props.comment.text}
                            <div className="position-absolute user-comment-like-count">
                                <span className="mx-1"><FontAwesomeIcon icon={faThumbsUp} size="sm"/></span>
                                {props.comment.likes.length}
                            </div>
                            <div className={`position-absolute comment-like-text ${isCommentAlreadyLiked() ? 'like-already-likes': ''}`} onClick={() => {
                                if(isCommentAlreadyLiked()) {
                                    dispatch(postCommentUnLikeAction(props.comment._id, props.postType, props.postId, userDetails.profileObj.googleId))
                                } else {
                                    dispatch(postCommentLikeAction(props.comment._id, props.postType, props.postId, userDetails.profileObj.googleId))
                                }
                            }}>Like</div>
                            <div title="Delete comment" className={`position-absolute comment-delete-text ${!canDeleteComment() ? 'd-none': ''}`} onClick={() => {
                                dispatch(postCommentDeleteAction(props.comment._id, props.postType, props.postId))
                            }}><FontAwesomeIcon icon={faTrashAlt} size="sm"/></div>
                            <div title="Edit comment" className={`position-absolute comment-edit-text ${!canDeleteComment() ? 'd-none': ''}`} onClick={() => {
                                setEditMode(!isEditMode);
                            }}><FontAwesomeIcon icon={faEdit} size="sm"/></div>
                        </p>
                    </Col>
                )
            }
        </Row>
    )
}

export default Comments;