import {getEnvData} from "../env";

export const getPost = (postType, postId) => {
    return fetch(`${getEnvData().api.url}/post/${postType}/${postId}`)
        .then(data => data.json())
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
}

export const addComments = (postType, postId, userName, profileImage, userId, text) => {
    return fetch(`${getEnvData().api.url}/comment/${postType}/${postId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            text,
            userName,
            profileImage,
            userId
        }),
    })
        .then(data => {
        console.log('Success:', data);
            return data.json();
    })
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
}

export const updatePostLike = (postType, postId, userId) => {
    return fetch(`${getEnvData().api.url}/like/${postType}/${postId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId
        }),
    })
        .then(data => {
            console.log('Success:', data);
            return data.json();
        })
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
}

export const postUnLike = (postType, postId, userId) => {
    return fetch(`${getEnvData().api.url}/unlike/${postType}/${postId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId
        }),
    })
        .then(data => {
            console.log('Success:', data);
            return data.json();
        })
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
}

export const updatePostCommentLike = (commentId, postType, postId, userId) => {
    return fetch(`${getEnvData().api.url}/like/${commentId}/${postType}/${postId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId
        }),
    })
        .then(data => {
            console.log('Success:', data);
            return data.json();
        })
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
}

export const postCommentUnLike = (commentId, postType, postId, userId) => {
    return fetch(`${getEnvData().api.url}/unlike/${commentId}/${postType}/${postId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId
        }),
    })
        .then(data => {
            console.log('Success:', data);
            return data.json();
        })
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
}
export const postCommentDelete = (commentId, postType, postId) => {
    return fetch(`${getEnvData().api.url}/comment/${postType}/${postId}/${commentId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(data => {
            console.log('Success:', data);
            return data.json();
        })
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
}

export const postCommentEdit = (commentId, postType, postId, commentText) => {
    return fetch(`${getEnvData().api.url}/comment/edit/${postType}/${postId}/${commentId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            commentText,
        }),
    })
        .then(data => {
            console.log('Success:', data);
            return data.json();
        })
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
}
