import {SET_USER_PROFILE} from "../actions/action-constants";

export default function userProfileReducer(state = { userDetails: null }, action) {
    switch (action.type) {
        case SET_USER_PROFILE: {
            return {
                userDetails: action.data
            }
        }
        default:
            return state;
    }
}