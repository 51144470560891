import {SET_POST} from "../actions/action-constants";

export default function postsReducer(state = { postDetails: { likes: [], comments: []} }, action) {
    switch (action.type) {
        case SET_POST:
            return {
                ...state,
                postDetails: {
                    ...action.data,
                    likes: action.data?.likes ? action.data.likes : [],
                    comments: action.data?.comments ? action.data.comments : [],
                }
            };
        default:
            return state;
    }
}